(function (global, factory) {
  typeof exports === 'object' && typeof module !== 'undefined' ? factory(exports) :
  typeof define === 'function' && define.amd ? define(['exports'], factory) :
  (global = typeof globalThis !== 'undefined' ? globalThis : global || self, factory(global.bcbp = {}));
})(this, (function (exports) { 'use strict';

  const hexToNumber = hex => parseInt(hex, 16);
  const numberToHex = n => n.toString(16).padStart(2, "0").toUpperCase();
  const dateToDayOfYear = (date, addYearPrefix = false) => {
    const oneDay = 1000 * 60 * 60 * 24;
    const dayOfYear = (Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()) - Date.UTC(date.getUTCFullYear(), 0, 0)) / oneDay;
    let yearPrefix = "";
    if (addYearPrefix) {
      yearPrefix = date.getUTCFullYear().toString().slice(-1);
    }
    return `${yearPrefix}${dayOfYear.toString()}`;
  };
  const dayOfYearToDate = (dayOfYear, hasYearPrefix, referenceYear) => {
    const currentYear = referenceYear ?? new Date(Date.now()).getUTCFullYear();
    let year = currentYear.toString();
    let daysToAdd = dayOfYear;
    if (hasYearPrefix) {
      year = year.toString().slice(0, -1) + daysToAdd[0];
      daysToAdd = daysToAdd.substring(1);
      if (parseInt(year) - currentYear > 2) {
        year = (parseInt(year) - 10).toString();
      }
    }
    const date = new Date(Date.UTC(parseInt(year), 0));
    return new Date(date.setUTCDate(parseInt(daysToAdd)));
  };

  const FORMAT_CODE = 1;
  const NUMBER_OF_LEGS = 1;
  const PASSENGER_NAME = 20;
  const ELECTRONIC_TICKET_INDICATOR = 1;
  const OPERATING_CARRIER_PNR = 7;
  const DEPARTURE_AIRPORT = 3;
  const ARRIVAL_AIRPORT = 3;
  const OPERATING_CARRIER_DESIGNATOR = 3;
  const FLIGHT_NUMBER = 5;
  const FLIGHT_DATE = 3;
  const COMPARTMENT_CODE = 1;
  const SEAT_NUMBER = 4;
  const CHECK_IN_SEQUENCE_NUMBER = 5;
  const PASSENGER_STATUS = 1;
  const VERSION_NUMBER_INDICATOR = 1;
  const VERSION_NUMBER = 1;
  const PASSENGER_DESCRIPTION = 1;
  const CHECK_IN_SOURCE = 1;
  const BOARDING_PASS_ISSUANCE_SOURCE = 1;
  const ISSUANCE_DATE = 4;
  const DOCUMENT_TYPE = 1;
  const BOARDING_PASS_ISSUER_DESIGNATOR = 3;
  const BAGGAGE_TAG_NUMBER = 13;
  const FIRST_BAGGAGE_TAG_NUMBER = 13;
  const SECOND_BAGGAGE_TAG_NUMBER = 13;
  const AIRLINE_NUMERIC_CODE = 3;
  const SERIAL_NUMBER = 10;
  const SELECTEE_INDICATOR = 1;
  const INTERNATIONAL_DOCUMENTATION_VERIFICATION = 1;
  const MARKETING_CARRIER_DESIGNATOR = 3;
  const FREQUENT_FLYER_AIRLINE_DESIGNATOR = 3;
  const FREQUENT_FLYER_NUMBER = 16;
  const ID_INDICATOR = 1;
  const FREE_BAGGAGE_ALLOWANCE = 3;
  const FAST_TRACK = 1;
  const SECURITY_DATA_INDICATOR = 1;
  const SECURITY_DATA_TYPE = 1;
  const SECURITY_DATA = 100;

  class SectionBuilder {
    output = [];
    fieldSizes = [];
    addField(field, length, addYearPrefix = false) {
      let value = "";
      if (field === undefined) {
        value = "";
      } else if (typeof field === "number") {
        value = field.toString();
      } else if (field instanceof Date) {
        value = dateToDayOfYear(field, addYearPrefix);
      } else if (typeof field === "boolean") {
        value = field ? "Y" : "N";
      } else {
        value = field;
      }
      let valueLength = value.length;
      if (length !== undefined) {
        if (valueLength > length) {
          value = value.substring(0, length);
        } else if (valueLength < length) {
          for (let i = 0; i < length - valueLength; i++) {
            value += " ";
          }
        }
      }
      this.output.push(value);
      this.fieldSizes.push({
        size: length ?? value.length,
        isDefined: field !== undefined
      });
    }
    addSection(section) {
      const sectionString = section.toString();
      let foundLastValue = false;
      let sectionLength = 0;
      for (let fieldSize of section.fieldSizes.reverse()) {
        if (!foundLastValue && fieldSize.isDefined) {
          foundLastValue = true;
        }
        if (foundLastValue) {
          sectionLength += fieldSize.size;
        }
      }
      this.addField(numberToHex(sectionLength), 2);
      this.addField(sectionString, sectionLength);
    }
    toString() {
      return this.output.join("");
    }
  }
  const encode = bcbp => {
    bcbp.meta = {
      formatCode: "M",
      numberOfLegs: bcbp?.data?.legs?.length ?? 0,
      electronicTicketIndicator: "E",
      versionNumberIndicator: ">",
      versionNumber: 6,
      securityDataIndicator: "^",
      ...bcbp.meta
    };
    const barcodeData = new SectionBuilder();
    if (bcbp.data?.legs === undefined || bcbp.data.legs.length === 0) {
      return "";
    }
    barcodeData.addField(bcbp.meta.formatCode, FORMAT_CODE);
    barcodeData.addField(bcbp.meta.numberOfLegs, NUMBER_OF_LEGS);
    barcodeData.addField(bcbp.data.passengerName, PASSENGER_NAME);
    barcodeData.addField(bcbp.meta.electronicTicketIndicator, ELECTRONIC_TICKET_INDICATOR);
    let addedUniqueFields = false;
    for (let leg of bcbp.data.legs) {
      barcodeData.addField(leg.operatingCarrierPNR, OPERATING_CARRIER_PNR);
      barcodeData.addField(leg.departureAirport, DEPARTURE_AIRPORT);
      barcodeData.addField(leg.arrivalAirport, ARRIVAL_AIRPORT);
      barcodeData.addField(leg.operatingCarrierDesignator, OPERATING_CARRIER_DESIGNATOR);
      barcodeData.addField(leg.flightNumber, FLIGHT_NUMBER);
      barcodeData.addField(leg.flightDate, FLIGHT_DATE);
      barcodeData.addField(leg.compartmentCode, COMPARTMENT_CODE);
      barcodeData.addField(leg.seatNumber, SEAT_NUMBER);
      barcodeData.addField(leg.checkInSequenceNumber, CHECK_IN_SEQUENCE_NUMBER);
      barcodeData.addField(leg.passengerStatus, PASSENGER_STATUS);
      const conditionalSection = new SectionBuilder();
      if (!addedUniqueFields) {
        conditionalSection.addField(bcbp.meta.versionNumberIndicator, VERSION_NUMBER_INDICATOR);
        conditionalSection.addField(bcbp.meta.versionNumber, VERSION_NUMBER);
        const sectionA = new SectionBuilder();
        sectionA.addField(bcbp.data.passengerDescription, PASSENGER_DESCRIPTION);
        sectionA.addField(bcbp.data.checkInSource, CHECK_IN_SOURCE);
        sectionA.addField(bcbp.data.boardingPassIssuanceSource, BOARDING_PASS_ISSUANCE_SOURCE);
        sectionA.addField(bcbp.data.issuanceDate, ISSUANCE_DATE, true);
        sectionA.addField(bcbp.data.documentType, DOCUMENT_TYPE);
        sectionA.addField(bcbp.data.boardingPassIssuerDesignator, BOARDING_PASS_ISSUER_DESIGNATOR);
        sectionA.addField(bcbp.data.baggageTagNumber, BAGGAGE_TAG_NUMBER);
        sectionA.addField(bcbp.data.firstBaggageTagNumber, FIRST_BAGGAGE_TAG_NUMBER);
        sectionA.addField(bcbp.data.secondBaggageTagNumber, SECOND_BAGGAGE_TAG_NUMBER);
        conditionalSection.addSection(sectionA);
        addedUniqueFields = true;
      }
      const sectionB = new SectionBuilder();
      sectionB.addField(leg.airlineNumericCode, AIRLINE_NUMERIC_CODE);
      sectionB.addField(leg.serialNumber, SERIAL_NUMBER);
      sectionB.addField(leg.selecteeIndicator, SELECTEE_INDICATOR);
      sectionB.addField(leg.internationalDocumentationVerification, INTERNATIONAL_DOCUMENTATION_VERIFICATION);
      sectionB.addField(leg.marketingCarrierDesignator, MARKETING_CARRIER_DESIGNATOR);
      sectionB.addField(leg.frequentFlyerAirlineDesignator, FREQUENT_FLYER_AIRLINE_DESIGNATOR);
      sectionB.addField(leg.frequentFlyerNumber, FREQUENT_FLYER_NUMBER);
      sectionB.addField(leg.idIndicator, ID_INDICATOR);
      sectionB.addField(leg.freeBaggageAllowance, FREE_BAGGAGE_ALLOWANCE);
      sectionB.addField(leg.fastTrack, FAST_TRACK);
      conditionalSection.addSection(sectionB);
      conditionalSection.addField(leg.airlineInfo);
      barcodeData.addSection(conditionalSection);
    }
    if (bcbp.data.securityData !== undefined) {
      barcodeData.addField(bcbp.meta.securityDataIndicator, SECURITY_DATA_INDICATOR);
      barcodeData.addField(bcbp.data.securityDataType ?? "1", SECURITY_DATA_TYPE);
      const securitySection = new SectionBuilder();
      securitySection.addField(bcbp.data.securityData, SECURITY_DATA);
      barcodeData.addSection(securitySection);
    }
    return barcodeData.toString();
  };

  class SectionDecoder {
    currentIndex = 0;
    constructor(barcodeString) {
      this.barcodeString = barcodeString;
    }
    getNextField(length) {
      if (this.barcodeString === undefined) {
        return;
      }
      const barcodeLength = this.barcodeString.length;
      if (this.currentIndex >= barcodeLength) {
        return;
      }
      let value;
      const start = this.currentIndex;
      if (length === undefined) {
        value = this.barcodeString.substring(start);
      } else {
        value = this.barcodeString.substring(start, start + length);
      }
      this.currentIndex += length ?? barcodeLength;
      const trimmedValue = value.trimEnd();
      if (trimmedValue === "") {
        return;
      }
      return trimmedValue;
    }
    getNextString(length) {
      return this.getNextField(length);
    }
    getNextNumber(length) {
      const value = this.getNextField(length);
      if (value === undefined) {
        return;
      }
      return parseInt(value);
    }
    getNextDate(length, hasYearPrefix, referenceYear) {
      const value = this.getNextField(length);
      if (value === undefined) {
        return;
      }
      return dayOfYearToDate(value, hasYearPrefix, referenceYear);
    }
    getNextBoolean() {
      const value = this.getNextField(1);
      if (value === undefined) {
        return;
      }
      return value === "Y";
    }
    getNextSectionSize() {
      return hexToNumber(this.getNextField(2) ?? "00");
    }
    getRemainingString() {
      return this.getNextField();
    }
  }
  const decode = (barcodeString, referenceYear) => {
    const bcbp = {};
    const mainSection = new SectionDecoder(barcodeString);
    bcbp.data = {};
    bcbp.meta = {};
    bcbp.meta.formatCode = mainSection.getNextString(FORMAT_CODE);
    bcbp.meta.numberOfLegs = mainSection.getNextNumber(NUMBER_OF_LEGS) ?? 0;
    bcbp.data.passengerName = mainSection.getNextString(PASSENGER_NAME);
    bcbp.meta.electronicTicketIndicator = mainSection.getNextString(ELECTRONIC_TICKET_INDICATOR);
    bcbp.data.legs = [];
    let addedUniqueFields = false;
    for (let legIndex = 0; legIndex < bcbp.meta.numberOfLegs; legIndex++) {
      const leg = {};
      leg.operatingCarrierPNR = mainSection.getNextString(OPERATING_CARRIER_PNR);
      leg.departureAirport = mainSection.getNextString(DEPARTURE_AIRPORT);
      leg.arrivalAirport = mainSection.getNextString(ARRIVAL_AIRPORT);
      leg.operatingCarrierDesignator = mainSection.getNextString(OPERATING_CARRIER_DESIGNATOR);
      leg.flightNumber = mainSection.getNextString(FLIGHT_NUMBER);
      leg.flightDate = mainSection.getNextDate(FLIGHT_DATE, false, referenceYear);
      leg.compartmentCode = mainSection.getNextString(COMPARTMENT_CODE);
      leg.seatNumber = mainSection.getNextString(SEAT_NUMBER);
      leg.checkInSequenceNumber = mainSection.getNextString(CHECK_IN_SEQUENCE_NUMBER);
      leg.passengerStatus = mainSection.getNextString(PASSENGER_STATUS);
      const conditionalSectionSize = mainSection.getNextSectionSize();
      const conditionalSection = new SectionDecoder(mainSection.getNextString(conditionalSectionSize));
      if (!addedUniqueFields) {
        bcbp.meta.versionNumberIndicator = conditionalSection.getNextString(VERSION_NUMBER_INDICATOR);
        bcbp.meta.versionNumber = conditionalSection.getNextNumber(VERSION_NUMBER);
        const sectionASize = conditionalSection.getNextSectionSize();
        const sectionA = new SectionDecoder(conditionalSection.getNextString(sectionASize));
        bcbp.data.passengerDescription = sectionA.getNextString(PASSENGER_DESCRIPTION);
        bcbp.data.checkInSource = sectionA.getNextString(CHECK_IN_SOURCE);
        bcbp.data.boardingPassIssuanceSource = sectionA.getNextString(BOARDING_PASS_ISSUANCE_SOURCE);
        bcbp.data.issuanceDate = sectionA.getNextDate(ISSUANCE_DATE, true, referenceYear);
        bcbp.data.documentType = sectionA.getNextString(DOCUMENT_TYPE);
        bcbp.data.boardingPassIssuerDesignator = sectionA.getNextString(BOARDING_PASS_ISSUER_DESIGNATOR);
        bcbp.data.baggageTagNumber = sectionA.getNextString(BAGGAGE_TAG_NUMBER);
        bcbp.data.firstBaggageTagNumber = sectionA.getNextString(FIRST_BAGGAGE_TAG_NUMBER);
        bcbp.data.secondBaggageTagNumber = sectionA.getNextString(SECOND_BAGGAGE_TAG_NUMBER);
        addedUniqueFields = true;
      }
      const sectionBSize = conditionalSection.getNextSectionSize();
      const sectionB = new SectionDecoder(conditionalSection.getNextString(sectionBSize));
      leg.airlineNumericCode = sectionB.getNextString(AIRLINE_NUMERIC_CODE);
      leg.serialNumber = sectionB.getNextString(SERIAL_NUMBER);
      leg.selecteeIndicator = sectionB.getNextString(SELECTEE_INDICATOR);
      leg.internationalDocumentationVerification = sectionB.getNextString(INTERNATIONAL_DOCUMENTATION_VERIFICATION);
      leg.marketingCarrierDesignator = sectionB.getNextString(MARKETING_CARRIER_DESIGNATOR);
      leg.frequentFlyerAirlineDesignator = sectionB.getNextString(FREQUENT_FLYER_AIRLINE_DESIGNATOR);
      leg.frequentFlyerNumber = sectionB.getNextString(FREQUENT_FLYER_NUMBER);
      leg.idIndicator = sectionB.getNextString(ID_INDICATOR);
      leg.freeBaggageAllowance = sectionB.getNextString(FREE_BAGGAGE_ALLOWANCE);
      leg.fastTrack = sectionB.getNextBoolean();
      leg.airlineInfo = conditionalSection.getRemainingString();
      bcbp.data.legs.push(leg);
    }
    bcbp.meta.securityDataIndicator = mainSection.getNextString(SECURITY_DATA_INDICATOR);
    bcbp.data.securityDataType = mainSection.getNextString(SECURITY_DATA_TYPE);
    const securitySectionSize = mainSection.getNextSectionSize();
    const securitySection = new SectionDecoder(mainSection.getNextString(securitySectionSize));
    bcbp.data.securityData = securitySection.getNextString(SECURITY_DATA);
    if (bcbp.data.issuanceDate !== undefined && referenceYear === undefined) {
      for (let leg of bcbp.data.legs) {
        if (leg.flightDate !== undefined) {
          const dayOfYear = dateToDayOfYear(leg.flightDate);
          leg.flightDate = dayOfYearToDate(dayOfYear, false, bcbp.data.issuanceDate.getFullYear());
        }
      }
    }
    return bcbp;
  };

  exports.decode = decode;
  exports.encode = encode;

}));
